var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto"},[_c('v-toolbar',{attrs:{"dense":"","color":"light-blue darken-4","dark":""}},[_c('v-toolbar-title',[_vm._v(" Çalışanlar ")]),_c('v-spacer'),_c('form-dialog-button',{attrs:{"icon":"mdi-plus","title":"Personel Bilgileri","color":"green","dark":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var formData = ref.formData;
var onInput = ref.onInput;
return [_c('personel-bilgi-form',{on:{"input":onInput},model:{value:(formData),callback:function ($$v) {formData=$$v},expression:"formData"}})]}}])})],1),(_vm.items!=null)?_c('v-data-table',{staticClass:"table table-striped",attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"no-data-text":"Büroya kayıtlı personel bulunamadı. Eklemek için + butonunu kullan! ","dense":""},scopedSlots:_vm._u([{key:"item.ad",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"seedetails",on:{"click":function($event){return _vm.detay(item)}}},[_vm._v(_vm._s(item.ad+" "+item.soyad))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"light-blue darken-4","icon":""},on:{"click":function($event){return _vm.detay(item)}}},[_c('v-icon',[_vm._v("mdi-arrow-right-bold-box")])],1),_c('delete-button')]}}],null,false,2454193342)}):_c('v-skeleton-loader',{attrs:{"type":"table"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }