




































import {Component, Prop, Vue} from "vue-property-decorator";
import {AdresEntity} from '../../../entity/AdresEntity';
import AdresForm from "@/components/comps/forms/AdresForm.vue";
import {AdresTuruLabel} from "@/enum/AdresTuru";
import FormDialogButton from "@/components/common-inputs/FormDialogButton.vue";

@Component({components: {FormDialogButton, AdresForm}})
export default class BuroAdresDetay extends Vue {

    AdresTuruLabels = AdresTuruLabel

    @Prop({})
    item!: AdresEntity;

    postData = Object.assign({}, this.item);

    dialog = false;

    displayEditAdresForm() {
        this.dialog = true;
    }

    mounted(){
        console.log("Adres: ", this.item);
    }
}
