





























































import {Component, Prop, Vue} from "vue-property-decorator";
import {BuroBilgiEntity} from "@/entity/BuroBilgiEntity";
import BuroBilgiForm from "@/components/buro/detay/BuroBilgiForm.vue";
import FormDialogButton from "@/components/common-inputs/FormDialogButton.vue";


@Component({
    components: {FormDialogButton, BuroBilgiForm}
})
export default class BuroBilgiList extends Vue {

    @Prop({})
    item!: BuroBilgiEntity;

    postData = Object.assign({}, this.item);

    dialog = false

    displayEditBuroBilgiForm() {
        this.dialog = true;
    }

    close() {
        this.dialog = false;
    }
}
