









































import {Component, Prop, Vue} from "vue-property-decorator";
import {PersonelEntity} from "@/entity/PersonelEntity";
import DeleteButton from "@/components/common-inputs/DeleteButton.vue";
import {AvukatPersonelEntity} from "@/entity/AvukatPersonelEntity";
import {DigerPersonelEntity} from "@/entity/DigerPersonelEntity";
import FormDialogButton from "@/components/common-inputs/FormDialogButton.vue";
import PersonelBilgiForm from "@/components/buro/personel/PersonelBilgiForm.vue";


@Component({
    components: {PersonelBilgiForm, FormDialogButton, DeleteButton}
})
export default class BuroPersonelList extends Vue {

    @Prop({})
    items!: AvukatPersonelEntity[]|DigerPersonelEntity[];

    creatingObject:any={
        girisCikis:[]
    };

    dialog: boolean = false;
    dialog1: boolean = false;

    displayPersonelEkleForm() {
        this.dialog1 = true;
    }
    displayPersonelDuzenleForm() {
        this.dialog1 = true;
    }

    detay(item: PersonelEntity) {
        this.$router.push('/personel/' + item.id?.toString() + '/detay');
    }

    search: string = "";

    headers=[
        {
            text: "Adı",
            align: "start",
            sortable: false,
            value: "ad"
        },
        {text: "Personel Türü", value: "personelTuru"},
        {text: "TC Kimlik No", value: "tc"},
        {text: "İşlemler", value: "actions", sortable: false}

    ];

}
