






















import {Component, Vue} from "vue-property-decorator";
import {BuroBilgiEntity} from "@/entity/BuroBilgiEntity";
import {BuroListResponseProvider} from "@/services/BuroService";
import BuroPersonelList from "@/components/buro/BuroPersonelList.vue";
import BuroAdresDetay from "@/components/buro/detay/BuroAdresDetay.vue"
import HesapNoList from "@/components/comps/lists/HesapNoList.vue";

import iletisimList from "@/components/comps/lists/IletisimList.vue";
import BuroBilgiDetay from "@/components/buro/detay/BuroBilgiList.vue";

@Component({
    components: {BuroBilgiDetay, iletisimList, HesapNoList, BuroPersonelList, BuroAdresDetay}
})
export default class BuroDetay extends Vue {

    item: BuroBilgiEntity | null = null;

    mounted() {

        window.setTimeout(() => {
            let index: number = parseInt(this.$route.params.id) - 1;
            this.item = BuroListResponseProvider().data[index];
            console.log("Buro Detay, Servisten Gelen DATA", this.item);
        }, 200);


    }

}
